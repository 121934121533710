<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <div v-if="item.band_sub" class="product-name-sub">{{ item.band_sub }}</div>
        <div v-if="item.band_sub2" class="product-name-sub2">{{ item.band_sub2 }}</div>
        <div v-if="item.text" class="product-name-sub2 mt-2">{{ item.text }}</div>
        <p v-if="item.sale_price" class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p v-if="item.price" :class="[item.sale_price ? 'product-price line-through' : 'product-price']">{{ item.price }}
        </p>
        <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts.scss";
export default {
  name: "HomeProducts",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "8056262346587",
          brand: "Prada",
          price: "R6520.00",
          sale_price: "",
          url: "/sunglasshut-sa/Prada/PR14YS-8056262346587",
          text: ""
        },
        {
          imageOne: "8056262349359",
          brand: "Prada",
          price: "R7320.00",
          sale_price: "",
          url: "/sunglasshut-sa/Prada/PRA17S--8056262349359",
          text: ""
        },
        {
          imageOne: "8056262083727",
          brand: "Prada",
          price: "R6190.00",
          sale_price: "",
          url: "/sunglasshut-sa/Prada/PR-17WS-8056262083727",
          text: ""
        },
        {
          imageOne: "8056262081167",
          brand: "Prada",
          price: "R6860.00",
          sale_price: "",
          url: "/sunglasshut-sa/Prada/PR-A14S-8056262081167",
          text: ""
        },
        {
          imageOne: "8056262134627",
          brand: "Prada",
          price: "R7320.00",
          sale_price: "",
          url: "/sunglasshut-sa/Prada/PRA17S--8056262134627",
          text: ""
        },
        {
          imageOne: "8056597627375",
          brand: "Prada",
          price: "R6520.00",
          sale_price: "",
          url: "/sunglasshut-sa/Prada/PR14YS-8056597627375",
          text: ""
        }
      ],
    };
  },
};
</script>
